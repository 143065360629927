import './App.css';
import { LoginSignup } from './Components/LoginSignup/LoginSignup';

function App() {
  return (
    <div>
      <LoginSignup/>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import "./LoginSignup.css";

import user_icon from '../Assets/user.png';
import email_icon from '../Assets/email.png';
import password_icon from '../Assets/password.png';

export const LoginSignup = () => {
    const [action, setAction] = useState("Login"); // Current action (Login, Sign Up, or Reset Password)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 6; // Example: Password must be at least 6 characters
    };

    const handleSubmit = () => {
        let validationErrors = {};

        if (action === "Sign Up" && !name) {
            validationErrors.name = "Name is required.";
        }
        if (!validateEmail(email)) {
            validationErrors.email = "Invalid email format.";
        }
        if (!validatePassword(password)) {
            validationErrors.password = "Password must be at least 6 characters.";
        }

        if (Object.keys(validationErrors).length === 0) {
            if (action === "Sign Up") {
                // Move to Login after successful sign-up
                setAction("Login");
                // Clear input fields
                setName("");
                setEmail("");
                setPassword("");
            } else if (action === "Login") {
                // Handle Login logic
                alert('Logged in successfully!');
            } else if (action === "Reset Password") {
                // Handle reset password logic
                alert('Password reset successfully!');
                setAction("Login");
                setEmail("");
                setPassword("");
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleForgotPasswordClick = () => {
        setAction("Reset Password");
        setErrors({});
    };

    return (
        <div className='container'>
            <div className="header">
                <div className="text">{action}</div>
                <div className="underline"></div>
            </div>
            <div className="inputs">
                {action === "Sign Up" && (
                    <div className="input">
                        <img src={user_icon} alt="" />
                        <input
                            type="text"
                            placeholder='Enter your Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                    </div>
                )}

                {(action === "Login" || action === "Sign Up" || action === "Reset Password") && (
                    <>
                        <div className="input">
                            <img src={email_icon} alt="" />
                            <input
                                type="email"
                                placeholder='Enter your email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="error">{errors.email}</div>}
                        </div>
                        <div className="input">
                            <img src={password_icon} alt="" />
                            <input
                                type="password"
                                placeholder={action === "Reset Password" ? 'Enter new password' : 'Enter your password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="error">{errors.password}</div>}
                        </div>
                    </>
                )}
            </div>
            {action === "Login" && (
                <div className="forgot-password">
                    Forgot Password? <span onClick={handleForgotPasswordClick}>Click Here</span>
                </div>
            )}

            <div className="submit-container">
                <div className={action === "Reset Password" ? "submit" : action === "Login" ? "submit gray" : "submit"} onClick={handleSubmit}>
                    {action === "Reset Password" ? "Reset Password" : action === "Login" ? "Login" : "Sign Up"}
                </div>
                {action !== "Reset Password" && (
                    <div className={action === "Sign Up" ? "submit gray" : "submit"} onClick={() => setAction(action === "Login" ? "Sign Up" : "Login")}>
                        {action === "Login" ? "Sign Up" : "Login"}
                    </div>
                )}
                {action === "Reset Password" && (
                    <div className="submit" onClick={() => setAction("Login")}>
                        Back to Login
                    </div>
                )}
            </div>
        </div>
    );
};
